import { useQuery } from 'react-query';
import { GetSessionContext } from '../apiCalls';
import { QueryKey } from './QueryKeys';

// Query for the session info.
const useSessionContext = () => {
	const { isLoading, isError, data, error } = useQuery(
		[QueryKey.GET_SESSION_CONTEXT],
		GetSessionContext,
		{ refetchOnWindowFocus: false }
	);

	const isLoggedIn = data?.resultCode === 0 && !!data?.data?.ashlinkUserId;

	const currentPermissions = data?.data?.permissions || [];
	const userInfoData = data?.data?.userInfo || {};
	const drFedId = data?.data?.drFedId || undefined;
	const currentLinks = data?.data?.ashlinkUserRedirections || [];

	return {
		isLoading,
		isError,
		error,
		isLoggedIn,
		permissions: currentPermissions,
		redirectionLinks: currentLinks,
		userInfo: userInfoData,
		drFedId,
	};
};

export default useSessionContext;
