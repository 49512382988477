import Typography from '@mui/material/Typography';
import ResourceTablePage from './shared/ResourceTablePage';
import FitnessFileType from '../../scripts/fitnessFileType';

const ClientSummaries = () => {
	const pageTitle = 'Client Summaries';
	const resourceColumnTitle = 'Client Summary Resource';
	const pageSummary = (
		<>
			<Typography fontWeight='fontWeightBold' mb={2}>
				Client Summaries outline eligible clients contracted with ASH.
			</Typography>
			<Typography mb={2}>
				The Attachments listed below provide comprehensive lists of all
				clients contracted with ASH to provider fitness services and
				personal trainer services for their members.
			</Typography>
		</>
	);

	return (
		<ResourceTablePage
			pageTitle={pageTitle}
			resourceColumnTitle={resourceColumnTitle}
			pageSummary={pageSummary}
			fitnessFileType={
				FitnessFileType.ashlinkFitnessClientSummaryResource.value
			}
			disableReleaseDateColumn
		/>
	);
};

export default ClientSummaries;
