import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ReactMarkdown from 'react-markdown';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import { GetFitnessPageFn } from '../../../data/apiCalls';
import useSessionContext from '../../../data/queryHooks/useSessionContext';
import CustomLoading from '../../shared/CustomLoading';
import CustomNoResultsOverlay from '../../shared/CustomNoResultsOverlay';
import PageLayout from '../../shared/PageLayout/PageLayout';

const FitnessPage = ({ pageTitle, pageId }) => {
	const { drFedId } = useSessionContext();

	const response = useQuery(
		['FitnessPage', drFedId],
		() => GetFitnessPageFn(drFedId, pageId),
		{ staleTime: Infinity }
	);

	const {
		data: {
			status,
			data: {
				resultCode,
				subResultCode,
				data: { result: fitnessPage = {} } = {},
			} = {},
		} = {},
		isLoading,
		isError,
	} = response;

	const errorMessage =
		"We're sorry, something went wrong and we're unable to load the page at this time.";

	const renderPage = () => {
		if (isError || (status >= 400 && status <= 505) || resultCode !== 0) {
			return (
				<CustomNoResultsOverlay
					resultCode={resultCode}
					statusCode={status}
					subResultCode={subResultCode}
					customNoResultsVerbiage={errorMessage}
					customFailureVerbiage={errorMessage}
				/>
			);
		}
		return (
			<>
				<Typography variant='h5' component='h1' mb={2}>
					{fitnessPage.title}
				</Typography>
				<Divider sx={{ mb: 2 }} />
				<Typography mb={2}>
					<ReactMarkdown>{fitnessPage.body}</ReactMarkdown>
				</Typography>
			</>
		);
	};

	return (
		<PageLayout pageTitle={pageTitle}>
			<Paper elevation={0} role='main'>
				<Container sx={{ py: 2, px: { md: 4, lg: 4 } }}>
					{isLoading ? <CustomLoading /> : renderPage()}
				</Container>
			</Paper>
		</PageLayout>
	);
};

FitnessPage.propTypes = {
	pageTitle: PropTypes.string.isRequired,
	pageId: PropTypes.string.isRequired,
};

export default FitnessPage;
