import FitnessPage from './shared/FitnessPage';

const WelcomePacket = () => {
	const pageTitle = 'Welcome Packet';
	const pageId = 'welcome-packet'; // Content Owners need to create a fitness page with this ID

	return <FitnessPage pageTitle={pageTitle} pageId={pageId} />;
};

export default WelcomePacket;
