import { Outlet, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useSessionContext from '../../../data/queryHooks/useSessionContext';
import {
	findPermissionInPermissions,
	getRedirectionLink,
	getHomePagePath,
} from '../../../scripts/navigationHelpers';
import {
	retrievePermissionWithKey,
	retrievePageKeyWithRoute,
} from '../../../scripts/pageConstants';
import { HOME_URL } from '../../../scripts/navigationItems';
import { FITNESS_PAGE_ROUTES } from '../../../scripts/routes';

const ProtectedRoute = ({ children }) => {
	const { isLoggedIn, permissions, redirectionLinks } = useSessionContext();
	const location = useLocation();
	const currentPageKey =
		retrievePageKeyWithRoute[location.pathname.toLowerCase()];
	const homeUrl = getHomePagePath(permissions, redirectionLinks) || HOME_URL;
	// use pageKey to get matching permission
	// find the permission from permissons,
	// if found, check to see if redirection link is available
	// if not found, redirect to login page

	if (permissions && currentPageKey) {
		let permissionFound = false;
		if (
			location.pathname.includes(
				FITNESS_PAGE_ROUTES.practitionerEducationLibrary
			)
		) {
			const pracEL = permissions.filter(perm =>
				perm.includes('PractitionerEducationLibrary')
			);
			permissionFound = pracEL.length > 0;
		} else if (
			location.pathname.includes(
				FITNESS_PAGE_ROUTES.providerEducationLibrary
			)
		) {
			const provEL = permissions.filter(perm =>
				perm.includes('ProviderEducationLibrary')
			);
			permissionFound = provEL.length > 0;
		} else {
			permissionFound = findPermissionInPermissions(
				retrievePermissionWithKey[currentPageKey],
				permissions
			);
		}

		if (!permissionFound) {
			window.open(homeUrl, '_self', 'noreferrer');
			return null;
		}
		// redirect the user if the redirection link is available
		const link = getRedirectionLink(currentPageKey, redirectionLinks);
		if (link) {
			window.open(link, '_self', 'noreferrer');
			return null;
		}
	}

	// redirect the user to the home page if no page key associated with the url
	if (!currentPageKey) {
		window.open(homeUrl, '_self', 'noreferrer');
		return null;
	}

	if (!isLoggedIn) {
		window.open(homeUrl, '_self', 'noreferrer');
		return null;
	}

	return children || <Outlet />;
};

export default ProtectedRoute;

ProtectedRoute.propTypes = {
	children: PropTypes.element,
};

ProtectedRoute.defaultProps = {
	children: null,
};
