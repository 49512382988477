import PropTypes from 'prop-types';

const MarkdownLinkRenderer = ({ href, children }) => (
	<a href={href} target='_blank' rel='noreferrer'>
		{children}
	</a>
);

MarkdownLinkRenderer.propTypes = {
	href: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

export default MarkdownLinkRenderer;
