import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const MarkdownParagraphRenderer = ({ children }) => (
	<Typography>{children}</Typography>
);

MarkdownParagraphRenderer.propTypes = {
	children: PropTypes.node.isRequired,
};

export default MarkdownParagraphRenderer;
