import { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

const NavigationButton = forwardRef((props, ref) => {
	const { palette } = useTheme();
	const { children, sx } = props;

	return (
		<Button
			size='small'
			ref={ref}
			{...props}
			sx={{
				borderRadius: 0,
				height: '100%',
				fontWeight: 700,
				color: palette.text.primary,
				paddingRight: '5px',
				paddingLeft: '5px',
				margin: '0 16px',
				minWidth: 'auto',
				...sx,
			}}
		>
			{children}
		</Button>
	);
});

NavigationButton.propTypes = {
	/**
	 * Additional styles to add to the button
	 */
	sx: PropTypes.object,
	/**
	 * Children to be wrapped by the component
	 */
	children: PropTypes.node.isRequired,
};

NavigationButton.defaultProps = {
	sx: {},
};

export default NavigationButton;
