import { useState, useRef, useEffect } from 'react';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';
import { useNavigate, useMatch, Link as RouterLink } from 'react-router-dom';
import NavigationButton from './NavigationButton';

const NavigationDropdown = ({
	buttonKey,
	buttonLabel,
	menuItems,
	activeNavigationButtonStyling,
}) => {
	const navigate = useNavigate();
	const isActive = useMatch(`/${buttonKey}/:pattern`);
	const [open, setOpen] = useState(false);
	const anchorRef = useRef(null);

	const handleToggle = () => {
		setOpen(prevOpen => !prevOpen);
	};

	const prevOpen = useRef(open);
	useEffect(() => {
		prevOpen.current = open;
	}, [open]);

	const handleButtonClick = () => {
		if (menuItems[0].isLegacy) {
			window.open(menuItems[0].path, '_self', 'noreferrer');
		} else {
			navigate(menuItems[0].path);
		}
	};

	return (
		<Stack direction='row' spacing={2} sx={{ height: '100%', zIndex: 1 }}>
			<div onMouseLeave={handleToggle} onMouseEnter={handleToggle}>
				<NavigationButton
					ref={anchorRef}
					id={`dropdown-button-${buttonKey}`}
					aria-controls={open ? 'dropdown-menu' : undefined}
					aria-expanded={open ? 'true' : undefined}
					aria-haspopup='true'
					sx={isActive ? activeNavigationButtonStyling : {}}
					onClick={handleButtonClick}
				>
					{buttonLabel}
					<KeyboardArrowDown fontSize='small' sx={{ ml: 1 }} />
				</NavigationButton>
				<Popper
					open={open}
					anchorEl={anchorRef.current}
					placement='bottom-start'
					transition
					disablePortal
				>
					{({ TransitionProps }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin: '0 0 0',
							}}
						>
							<Paper>
								<MenuList
									id='dropdown-menu'
									aria-labelledby={`dropdown-button-${buttonKey}`}
								>
									{menuItems.map(item =>
										item.isLegacy ? (
											<MenuItem
												key={item.key}
												component={Link}
												href={item.path}
												target='_self'
												rel='referrer'
											>
												{item.label}
											</MenuItem>
										) : (
											<MenuItem
												key={item.key}
												onClick={handleToggle}
												component={RouterLink}
												to={item.path}
												selected={
													isActive &&
													isActive.pathnameBase ===
														item.path
												}
											>
												{item.label}
											</MenuItem>
										)
									)}
								</MenuList>
							</Paper>
						</Grow>
					)}
				</Popper>
			</div>
		</Stack>
	);
};

NavigationDropdown.propTypes = {
	buttonKey: PropTypes.string.isRequired,
	buttonLabel: PropTypes.string.isRequired,
	menuItems: PropTypes.array.isRequired,
	activeNavigationButtonStyling: PropTypes.object.isRequired,
};

export default NavigationDropdown;
