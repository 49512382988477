import { GetStatus } from '../data/apiCalls';

export const idleTracker = (isLoggedIn, setModalOpen) => {
	const logoutAfterWarningInMilliseconds = 180000;
	let idleTimer;

	const checkIdleTimer = async () => {
		let newActiveTime;
		try {
			const res = await GetStatus();
			newActiveTime = res.data.inactiveTimeLeft;
		} catch (error) {
			return error;
		}
		window.clearTimeout(idleTimer);
		if (newActiveTime && logoutAfterWarningInMilliseconds < newActiveTime) {
			idleTimer = window.setTimeout(
				checkIdleTimer,
				newActiveTime - logoutAfterWarningInMilliseconds
			);
		} else {
			setModalOpen();
		}
		return null;
	};

	if (isLoggedIn) {
		checkIdleTimer();
	}
};
