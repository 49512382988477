import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { useTheme } from '@mui/material/styles';
import useSessionContext from '../../../data/queryHooks/useSessionContext';

// export const SWITCH_CLINIC_URL = `/ASH/site/Authentication/chooseclinic.aspx`;

const UserInfo = () => {
	const { palette } = useTheme();

	const { userInfo } = useSessionContext();
	const {
		lastName = '',
		firstName = '',
		address1 = '',
		city = '',
		state = '',
		zipCode = '',
	} = userInfo;

	return (
		<Stack
			spacing={2}
			divider={
				<Box
					display='flex'
					sx={{
						borderRight: '1px solid',
						backgroundColor: palette.text.light,
					}}
				/>
			}
			color={palette.text.primary}
			direction='row'
			justifyContent='flex-end'
		>
			<Typography variant='subtitle2'>
				{`${firstName} ${lastName}`}
			</Typography>
			<Typography variant='subtitle2'>
				{`${address1} - ${city}, ${state} ${zipCode}`}
			</Typography>
			<Typography variant='subtitle2'>Fitness</Typography>
			{/* TODO: only show to master accounts */}
			{/* <Link href={SWITCH_CLINIC_URL} variant='subtitle2'>
				Switch Fitness Center
			</Link> */}
		</Stack>
	);
};

export default UserInfo;
