import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import EveThemeProvider from '@ashn/eve-themes-react/EveThemeProvider';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

async function main() {
	if (process.env.NODE_ENV === 'development') {
		if (window.location.pathname === '/fitness') {
			window.location.pathname = '/fitness/';
			return;
		}
		// eslint-disable-next-line global-require
		const { worker } = require('./mocks/browser');
		await worker.start({
			serviceWorker: {
				url: '/fitness/mockServiceWorker.js',
			},
		});
	}
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter basename='/fitness'>
		<QueryClientProvider client={new QueryClient()}>
			<React.StrictMode>
				<EveThemeProvider themeKey='internal' themeMode='light'>
					<CssBaseline />
					<App />
				</EveThemeProvider>
			</React.StrictMode>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

main();
