import FitnessFileType from '../../scripts/fitnessFileType';
import ShowMoreText from '../shared/ShowMoreText';
import ResourceTablePage from './shared/ResourceTablePage';

const ProgramManual = () => {
	const pageTitle = 'Program Manual';
	const resourceColumnTitle = 'Program Manual Resources';
	const pageSummaryText = `The Program Manual provides an overview of the ASH Fitness programs.
It also contains the administrative procedures that contracted
fitness centers and their staff should review. Contracted fitness
centers should read this manual in its entirety, as they are
accountable for all guidelines.`;
	const pageSummary = <ShowMoreText text={pageSummaryText} />;

	return (
		<ResourceTablePage
			pageTitle={pageTitle}
			resourceColumnTitle={resourceColumnTitle}
			pageSummary={pageSummary}
			fitnessFileType={
				FitnessFileType.ashlinkFitnessProgramManualResource.value
			}
			disableReleaseDateColumn
		/>
	);
};

export default ProgramManual;
