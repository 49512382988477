import PageLayout from '../shared/PageLayout/PageLayout';
// import useSessionContext from '../../data/queryHooks/useSessionContext';
// import { getResourcesNavs } from '../../scripts/navigationHelpers';

const Resources = () => (
	// const { permissions, redirectionLinks } = useSessionContext();
	<PageLayout
		pageTitle='Resources'
		// navItems={getResourcesNavs(permissions, redirectionLinks)}
		// permissions={permissions}
	/>
);
export default Resources;
