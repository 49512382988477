import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';

const CustomLoading = () => (
	<Container
		sx={{
			textAlign: 'center',
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		}}
	>
		<CircularProgress aria-label='Loading' />
	</Container>
);

export default CustomLoading;
