import Typography from '@mui/material/Typography';
import ResourceTablePage from './shared/ResourceTablePage';
import FitnessFileType from '../../scripts/fitnessFileType';

const fitnessFileType = FitnessFileType.ashlinkFitnessFormResource.value;
const Forms = () => {
	const pageTitle = 'Forms';
	const pageSummary = (
		<Typography fontWeight='fontWeightBold' mb={2}>
			The Forms section is a complete list of ASH forms for viewing and
			printing.
		</Typography>
	);
	const resourceColumnTitle = 'Form';

	return (
		<ResourceTablePage
			pageTitle={pageTitle}
			resourceColumnTitle={resourceColumnTitle}
			pageSummary={pageSummary}
			fitnessFileType={fitnessFileType}
			disableEffectiveDateColumn
			disableReleaseDateColumn
		/>
	);
};

export default Forms;
