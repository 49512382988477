import { Link } from 'react-router-dom';
import ashlinkLogo from '../../../assets/ASHlink_Logo.svg';
import useSessionContext from '../../../data/queryHooks/useSessionContext';
import { getHomePagePath } from '../../../scripts/navigationHelpers';

const HomeLink = () => {
	const { permissions, redirectionLinks } = useSessionContext();
	return (
		<Link to={getHomePagePath(permissions, redirectionLinks)}>
			<img
				src={ashlinkLogo}
				alt='ASHLink Logo'
				width='128px'
				style={{ paddingTop: '5px' }}
			/>
		</Link>
	);
};
export default HomeLink;
