import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const CustomNoResultsOverlay = ({
	statusCode,
	resultCode,
	subResultCode,
	customNoResultsVerbiage,
	customFailureVerbiage,
}) => (
	<Container
		sx={{
			textAlign: 'center',
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		}}
	>
		{/*
			This component is being used when there
			is no data for DataGrid to display.

			NoRowsOverlay:
			- Triggered by API calls which return 0 rows of data
			- Passed a statusCode of 404 or a subResultCode of 9001

			NoResultsOverlay:
			- Triggered by returned data being filtered down to 0 rows via search
			- Passed a resultCode of 0
		*/}
		{resultCode === 0 || statusCode === 404 || subResultCode === 9001 ? (
			<Typography>{customNoResultsVerbiage}</Typography>
		) : (
			<Typography>{customFailureVerbiage}</Typography>
		)}
	</Container>
);

CustomNoResultsOverlay.propTypes = {
	statusCode: PropTypes.number,
	subResultCode: PropTypes.number,
	resultCode: PropTypes.number,
	customNoResultsVerbiage: PropTypes.string,
	customFailureVerbiage: PropTypes.string,
};
CustomNoResultsOverlay.defaultProps = {
	statusCode: 0,
	subResultCode: 0,
	resultCode: 0,
	customNoResultsVerbiage: 'No Resources Available',
	customFailureVerbiage:
		"We're sorry, something went wrong. We're unable to load the results at this time. Please refresh and try again.",
};

export default CustomNoResultsOverlay;
