import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import NavigationDropdown from './NavigationDropdown';
import NavigationButton from './NavigationButton';

const NavigationBar = ({ items }) => {
	const { palette } = useTheme();
	const activeNavigationButtonStyling = {
		color: palette.secondary.main,
		borderBottom: `3px solid ${palette.secondary.main}`,
	};

	return (
		<Box sx={{ bgcolor: palette.common.white }}>
			<Container sx={{ padding: { sm: 0 } }}>
				<Toolbar
					role='navigation'
					disableGutters
					sx={{ height: '48px', minHeight: { sm: '48px' } }}
				>
					{items.map(item => {
						if (item.isDropdown) {
							return (
								<NavigationDropdown
									key={item.key}
									buttonKey={item.key}
									buttonLabel={item.label}
									menuItems={item.menuItems}
									activeNavigationButtonStyling={
										activeNavigationButtonStyling
									}
								/>
							);
						}
						return (
							<NavigationButton
								key={item.key}
								{...(item.isLegacy
									? {
											component: Link,
											href: item.path,
											target: item.isExternal
												? '_blank'
												: '_self',
											rel: 'noreferrer',
									  }
									: { component: RouterLink, to: item.path })}
							>
								{item.label}
							</NavigationButton>
						);
					})}
				</Toolbar>
			</Container>
		</Box>
	);
};

NavigationBar.propTypes = {
	items: PropTypes.array.isRequired,
};

export default NavigationBar;
