import { Outlet } from 'react-router-dom';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import EveThemeProvider from '@ashn/eve-themes-react/EveThemeProvider';
// import SideNavigation from '../SideNavigation/SideNavigation';
// import useIsMobile from '../../../hooks/useIsMobile';

const PageLayout = ({ pageTitle, children }) => (
	// const isMobile = useIsMobile();

	<EveThemeProvider themeKey='internal' themeMode='light'>
		<Container>
			<Typography variant='h4' component='h1' mb={2} pt={3}>
				{pageTitle}
			</Typography>
			<Divider sx={{ mb: 4 }} />
			<Grid container direction='row' spacing={3}>
				{/* {!isMobile && navItems && (
						<Grid item sx={{ width: '274px' }}>
							<SideNavigation
								pages={navItems}
								permissions={permissions}
							/>
						</Grid>
					)} */}
				<Grid item xs>
					{children}
					<Outlet />
				</Grid>
			</Grid>
		</Container>
	</EveThemeProvider>
);
export default PageLayout;

PageLayout.propTypes = {
	pageTitle: PropTypes.string.isRequired,
	children: PropTypes.node,
};

PageLayout.defaultProps = {
	children: undefined,
};
