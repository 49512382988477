/* eslint-disable lines-between-class-members */
class FitnessFileType {
	// Create new instances of the same class as static attributes
	static ashlinkFitnessProviderServicesAgreementResource =
		new FitnessFileType(1);
	static ashlinkFitnessClientSummaryResource = new FitnessFileType(2);
	static ashlinkFitnessProgramManualResource = new FitnessFileType(3);
	static ashlinkFitnessFormResource = new FitnessFileType(4);
	static ashlinkFitnessNotificationResource = new FitnessFileType(5);
	static ashlinkFitnessPELResource = new FitnessFileType(6);
	constructor(value) {
		this.value = value;
	}
}

export default FitnessFileType;
