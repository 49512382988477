import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme, alpha } from '@mui/material/styles';
import useSessionContext from '../../../data/queryHooks/useSessionContext';

// export const SWITCH_CLINIC_URL = `/ASH/site/Authentication/chooseclinic.aspx`;

const UserInfoHeaderMobile = () => {
	const { palette } = useTheme();
	const transparentBackground = alpha(palette.primary.main, 0.08);

	const { userInfo } = useSessionContext();
	const {
		lastName = '',
		firstName = '',
		address1 = '',
		city = '',
		state = '',
		zipCode = '',
	} = userInfo;

	return (
		<Box
			sx={{
				padding: '16px',
				display: 'flex',
				height: '178px',
				backgroundColor: transparentBackground,
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
			}}
		>
			<Stack
				color={palette.text.primary}
				direction='column'
				justifyContent='flex-end'
				spacing={2}
			>
				<Typography variant='subtitle2'>
					{`${firstName} ${lastName}`}
				</Typography>
				<Typography variant='subtitle2'>
					{`${address1} - ${city}, ${state} ${zipCode}`}
				</Typography>
				<Typography variant='subtitle2'>Fitness</Typography>
				{/* TODO: only show to master accounts */}
				{/* <Link href={SWITCH_CLINIC_URL} variant='subtitle2'>
					Switch Clinic
				</Link> */}
			</Stack>
		</Box>
	);
};
export default UserInfoHeaderMobile;
