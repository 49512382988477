import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { Link as RouterLink } from 'react-router-dom';
import { arrayOf, bool, element, shape, string } from 'prop-types';

const FooterMenu = ({ menuItems }) => (
	<Grid container direction='row' mt={{ xs: 0, md: 8 }} spacing={3.5}>
		{menuItems &&
			menuItems.map(menuItem => (
				<Grid item xs={6} sm={6} lg={6} key={menuItem.label}>
					{menuItem.component ? (
						<Box
							sx={{
								a: {
									color: 'text.primary',
									textDecoration: 'none',
									fontSize: '1rem',
									fontFamily: 'Inter',
									'&:hover': {
										textDecoration: 'underline',
									},
								},
							}}
						>
							<RouterLink
								to={menuItem.path}
								target={menuItem.newTab ? '_blank' : '_self'}
								rel={menuItem.newTab ? 'noopener' : ''}
							>
								{menuItem.label}
							</RouterLink>
						</Box>
					) : (
						<Link
							color='inherit'
							variant='body1'
							href={menuItem.path}
							target={menuItem.newTab ? '_blank' : '_self'}
							rel={menuItem.newTab ? 'noopener' : ''}
							underline='hover'
						>
							{menuItem.label}
						</Link>
					)}
				</Grid>
			))}
	</Grid>
);

FooterMenu.propTypes = {
	menuItems: arrayOf(
		shape({
			label: string.isRequired,
			path: string.isRequired,
			newTab: bool,
			component: element,
		})
	).isRequired,
};

export default FooterMenu;
