import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const ShowMoreText = ({ text }) => {
	const [showMore, setShowMore] = useState(false);

	return (
		<div>
			<Typography fontWeight='fontWeightBold' mb={2}>
				<Box
					sx={{
						display: {
							xs: 'none',
							sm: 'none',
							md: 'block',
						},
					}}
				>
					{text}
				</Box>
				<Box
					sx={{
						display: {
							xs: 'block',
							sm: 'block',
							md: 'none',
						},
					}}
				>
					{showMore ? text : `${text.substring(0, 56)}...`}
					<Button onClick={() => setShowMore(!showMore)}>
						{showMore ? 'Show less' : 'Show more'}
					</Button>
				</Box>
			</Typography>
		</div>
	);
};

ShowMoreText.propTypes = {
	text: PropTypes.string.isRequired,
};

ShowMoreText.defaultProps = {};

export default ShowMoreText;
