import Typography from '@mui/material/Typography';
import ResourceTablePage from './shared/ResourceTablePage';
import FitnessFileType from '../../scripts/fitnessFileType';

const Notifications = () => {
	const pageTitle = 'Notifications';
	const pageSummary = (
		<Typography fontWeight='fontWeightBold' mb={2}>
			Notifications are announcements and alerts that are sent to ASH
			contracted fitness centers containing important updates and/or
			revisions.
		</Typography>
	);
	const resourceColumnTitle = 'Topic';

	return (
		<ResourceTablePage
			pageTitle={pageTitle}
			resourceColumnTitle={resourceColumnTitle}
			pageSummary={pageSummary}
			fitnessFileType={
				FitnessFileType.ashlinkFitnessNotificationResource.value
			}
		/>
	);
};

export default Notifications;
