import { Route, Routes, useLocation } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';

import Footer from './components/shared/Footer/Footer';
import ClientSummaries from './components/Resources/ClientSummaries';
import ProgramManual from './components/Resources/ProgramManual';
import Resources from './components/Resources/Resources';
import WelcomePacket from './components/Pages/WelcomePacket';
import Compliance from './components/Pages/Compliance';
import Notifications from './components/Resources/Notifications';
import Header from './components/shared/Header/Header';
import FAQ from './components/Help/FAQ';
import Help from './components/Help/Help';
import ProtectedRoute from './components/shared/ProtectedRoute/ProtectedRoute';
import { FITNESS_PAGE_ROUTES, TOP_LEVEL_ROUTES } from './scripts/routes';
import FitnessServicesAgreements from './components/Resources/FitnessServicesAgreements';
import FitnessInstructorServicesAgreements from './components/Resources/FitnessInstructorServicesAgreements';
import ProgramEducationLibrary from './components/Resources/ProgramEducationLibrary';
import useSessionContext from './data/queryHooks/useSessionContext';
import CustomLoading from './components/shared/CustomLoading';
import { HOME_URL, LOGOUT_URL } from './scripts/navigationItems';
import IdleModal from './components/shared/Modal/IdleModal';
import { idleTracker } from './scripts/idleTracker';
import { GetSessionContext } from './data/apiCalls';
import Forms from './components/Resources/Forms';

function App() {
	const { isLoading, isLoggedIn } = useSessionContext();
	const location = useLocation();
	const [modalOpen, setModalOpen] = useState(false);
	const [staySingedIn, setStaySignedIn] = useState(false);

	const openModal = () => {
		setModalOpen(true);
	};

	const checkSession = async () => {
		try {
			const res = await GetSessionContext();
			if (res?.resultCode !== 0)
				window.open(LOGOUT_URL, '_self', 'noreferrer');
		} catch (error) {
			return error;
		}
		return null;
	};
	useEffect(() => {
		checkSession();
	}, [location.pathname]);
	useEffect(() => {
		if (staySingedIn) {
			setModalOpen(false);
			idleTracker(isLoggedIn, openModal);
			setStaySignedIn(false);
		}
	}, [staySingedIn]);

	useEffect(() => {
		idleTracker(isLoggedIn, openModal);
	}, [isLoggedIn]);

	if (isLoading) {
		return (
			<Container sx={{ mt: 7 }}>
				<CustomLoading />
			</Container>
		);
	}

	if (!isLoggedIn) {
		window.open(HOME_URL, '_self', 'noreferrer');
		return (
			<Container sx={{ mt: 7 }}>
				<CustomLoading />
			</Container>
		);
	}

	return (
		<Stack sx={{ minHeight: '100vh' }}>
			<Header />
			<IdleModal
				open={modalOpen}
				setSignedIn={() => setStaySignedIn(true)}
			/>
			<Routes>
				<Route element={<ProtectedRoute />}>
					<Route
						path={TOP_LEVEL_ROUTES.resources}
						element={<Resources />}
					>
						<Route
							path={FITNESS_PAGE_ROUTES.forms}
							element={<Forms />}
						/>
						<Route
							path={FITNESS_PAGE_ROUTES.clientSummaries}
							element={<ClientSummaries />}
						/>
						<Route
							path={FITNESS_PAGE_ROUTES.programManual}
							element={<ProgramManual />}
						/>
						<Route
							path={FITNESS_PAGE_ROUTES.notifications}
							element={<Notifications />}
						/>
						<Route
							path={FITNESS_PAGE_ROUTES.programEducationLibrary}
							element={<ProgramEducationLibrary />}
						/>
						<Route
							path={FITNESS_PAGE_ROUTES.fitnessServiceAgreements}
							element={<FitnessServicesAgreements />}
						/>
						<Route
							path={
								FITNESS_PAGE_ROUTES.fitnessInstructorServicesAgreements
							}
							element={<FitnessInstructorServicesAgreements />}
						/>
						<Route
							path={FITNESS_PAGE_ROUTES.compliance}
							element={<Compliance />}
						/>
					</Route>
					<Route path={TOP_LEVEL_ROUTES.help} element={<Help />}>
						<Route
							path={FITNESS_PAGE_ROUTES.faq}
							element={<FAQ />}
						/>
					</Route>
					<Route
						path={TOP_LEVEL_ROUTES.welcomePacket}
						element={<WelcomePacket />}
					/>
					<Route path='*' element={null} />
				</Route>
			</Routes>
			<Footer />
		</Stack>
	);
}

export default App;
