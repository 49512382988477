import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Collapse from '@mui/material/Collapse';
import ListItemText from '@mui/material/ListItemText';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import UserInfoHeaderMobile from './UserInfoHeaderMobile';

const MobileAccountMenu = ({ profileItems, handleLogout }) => {
	const { palette } = useTheme();
	const [drawerOpen, setDrawerOpen] = useState(false);

	return (
		<>
			<IconButton
				aria-label='Mobile account menu'
				aria-haspopup='dialog'
				data-testid='mobile-profile-menu'
				onClick={() => setDrawerOpen(true)}
			>
				{/* TODO: swtich to theme color when using eve theme */}
				<PersonIcon sx={{ color: 'white' }} />
			</IconButton>
			<Drawer
				onClose={() => setDrawerOpen(false)}
				open={drawerOpen}
				role='navigation'
				PaperProps={{ sx: { width: '66%' } }}
			>
				<List component='div'>
					<ListItemButton selected>
						<ListItemText primary='My Account' />
						<KeyboardArrowRightIcon />
					</ListItemButton>
					<Collapse in timeout='auto' unmountOnExit>
						<List component='div'>
							{profileItems.map(menuItem => (
								<ListItemButton
									key={menuItem.key}
									{...(menuItem.isLegacy
										? {
												component: Link,
												href: menuItem.path,
												target: '_self',
												rel: 'noreferrer',
										  }
										: {
												component: RouterLink,
												to: menuItem.path,
												onClick: () =>
													setDrawerOpen(false),
										  })}
									sx={{
										color: palette.text.primary,
										pl: 4,
									}}
								>
									<ListItemText primary={menuItem.label} />
								</ListItemButton>
							))}
							<ListItemButton
								key='log-out'
								onClick={handleLogout}
								sx={{
									color: palette.text.primary,
									pl: 4,
								}}
							>
								Log Out
							</ListItemButton>
						</List>
					</Collapse>
				</List>
				<Divider />
				<UserInfoHeaderMobile />
			</Drawer>
		</>
	);
};

MobileAccountMenu.propTypes = {
	profileItems: PropTypes.array.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default MobileAccountMenu;
