import { Link as RouterLink } from 'react-router-dom';
import { TOP_LEVEL_ROUTES, FITNESS_PAGE_ROUTES } from './routes';
import { pageKey } from './pageConstants';

export const ASHCareSubdomain = () => {
	const { origin } = window.location;
	if (origin.includes('localhost') || origin.includes('dev')) {
		return 'dev.';
	}
	if (origin.includes('stg')) {
		return 'stg.';
	}
	if (origin.includes('tst')) {
		return 'tst.';
	}
	return '';
};

export const HOME_URL = `/ASH/default.aspx`;
// when testing this locally, the user will be signed out of the dev fitness ashlink site
export const LOGOUT_URL = `/fitness/auth/logout`;
export const ASHCARE_URL = `//${ASHCareSubdomain()}portal.ashcare.com/landing`;

const FAQ_PATH = `/${TOP_LEVEL_ROUTES.help}/${FITNESS_PAGE_ROUTES.faq}`;

export const MESSAGE_CENTER = {
	key: pageKey.messageCenter,
	label: 'Message Center',
};

export const PROFILE_ITEMS = [
	{
		key: pageKey.accountManagement,
		label: 'Account Management',
		order: 1,
	},
];

export const FOOTER_ITEMS = [
	{
		key: pageKey.contactUs,
		label: 'Contact Us',
		newTab: false,
	},
	{
		key: pageKey.privacyStatement,
		label: 'Privacy Statement',
		newTab: false,
	},
	{
		key: pageKey.termsAndCondition,
		label: 'Terms & Conditions',
		newTab: false,
	},
	{
		key: pageKey.frequentlyAskedQuestion,
		label: 'FAQ',
		Component: RouterLink,
		path: FAQ_PATH,
		newTab: false,
	},
];

export const HELP_NAV_ITEMS = [
	{
		key: pageKey.frequentlyAskedQuestion,
		label: 'Frequently Asked Questions (FAQs)',
		path: FAQ_PATH,
		order: 1,
	},
	{
		key: pageKey.ASHLinkWebsiteTip,
		label: 'ASHLink Website Tips',
		order: 2,
	},
	{
		key: pageKey.contactUs,
		label: 'Contact Us',
		order: 3,
	},
	{
		key: pageKey.submitAnOnlineQuestionOrComment,
		label: 'Submit an online question or comment',
		order: 4,
	},
];

export const RESOURCES_NAV_ITEMS = [
	{
		key: pageKey.fitnessServiceAgreement,
		path: `/${TOP_LEVEL_ROUTES.resources}/${FITNESS_PAGE_ROUTES.fitnessServiceAgreements}`,
		label: 'Fitness Services Agreements',
		order: 1,
	},
	{
		key: pageKey.fitnessInstructorServiceAgreement,
		path: `/${TOP_LEVEL_ROUTES.resources}/${FITNESS_PAGE_ROUTES.fitnessInstructorServicesAgreements}`,
		label: 'Fitness Instructor Services Agreements',
		order: 2,
	},
	{
		key: pageKey.clientSummary,
		path: `/${TOP_LEVEL_ROUTES.resources}/${FITNESS_PAGE_ROUTES.clientSummaries}`,
		label: 'Client Summaries',
		order: 3,
	},
	{
		key: pageKey.programManual,
		path: `/${TOP_LEVEL_ROUTES.resources}/${FITNESS_PAGE_ROUTES.programManual}`,
		label: 'Program Manuals',
		order: 4,
	},
	{
		key: pageKey.forms,
		path: `/${TOP_LEVEL_ROUTES.resources}/${FITNESS_PAGE_ROUTES.forms}`,
		label: 'Forms',
		order: 5,
	},
	{
		key: pageKey.notification,
		label: 'Notifications',
		path: `/${TOP_LEVEL_ROUTES.resources}/${FITNESS_PAGE_ROUTES.notifications}`,
		order: 6,
	},
	{
		key: pageKey.programEducationLibrary,
		label: 'Program Education Library',
		path: `/${TOP_LEVEL_ROUTES.resources}/${FITNESS_PAGE_ROUTES.programEducationLibrary}`,
		order: 7,
	},
	{
		key: pageKey.compliance,
		path: `/${TOP_LEVEL_ROUTES.resources}/${FITNESS_PAGE_ROUTES.compliance}`,
		label: 'Compliance',
		order: 8,
	},
];

export const MEMBER_MANAGEMENT_NAV_ITEMS = [
	{
		key: pageKey.memberTerminationReport,
		label: 'Member Termination Report',
		order: 1,
	},
	{
		key: pageKey.memberElgibilitySearch,
		label: 'Member Eligibility Search',
		order: 2,
	},
];

export const ACCOUNT_NAV_ITEMS = [
	{
		key: pageKey.accountManagement,
		label: 'Account Management',
		order: 1,
	},
	{
		key: pageKey.confirmUpdateInformation,
		label: 'Confirm/Update Your Information',
		order: 2,
	},
	{
		key: pageKey.messageCenter,
		label: 'Message Center',
		order: 3,
	},
];

export const BILLING_NAV_ITEMS = [
	{
		key: pageKey.bulkClaimUpload,
		label: 'Bulk Claims Upload',
		order: 1,
	},
	{
		key: pageKey.bulkClaimSummary,
		label: 'Bulk Claims Summary',
		order: 2,
	},
	{
		key: pageKey.memberBillingReport,
		label: 'Member Billing Report',
		order: 3,
	},
	{
		key: pageKey.claimsAndPayments,
		label: 'Claims & Payments',
		order: 4,
	},
];

export const TOP_NAV_ITEMS = [
	{
		key: pageKey.home,
		label: 'Home',
		isDropdown: false,
		order: 1,
	},
	{
		key: 'member-management',
		label: 'Member Management',
		isDropdown: true,
		menuItems: MEMBER_MANAGEMENT_NAV_ITEMS,
		order: 2,
	},
	{
		key: 'resources',
		label: 'Resources',
		isDropdown: true,
		menuItems: RESOURCES_NAV_ITEMS,
		order: 4,
	},
	{
		key: 'billing-and-payments',
		label: 'Billing & Payments',
		isDropdown: true,
		menuItems: BILLING_NAV_ITEMS,
		order: 5,
	},
	{
		key: 'help',
		label: 'Help',
		isDropdown: true,
		menuItems: HELP_NAV_ITEMS,
		order: 6,
	},
	{
		key: pageKey.welcomePacket,
		label: 'Welcome Packet',
		isDropdown: false,
		order: 7,
		path: TOP_LEVEL_ROUTES.welcomePacket,
	},
];
