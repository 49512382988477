import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListItemText from '@mui/material/ListItemText';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import { Link as RouterLink, useMatch } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

const MobileNavMenu = ({ navigationItems }) => {
	const { palette } = useTheme();
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [open, setOpen] = useState(null);
	const isActive = useMatch(`/:topNav/:page`);

	return (
		<>
			<IconButton
				aria-label='Mobile menu'
				aria-haspopup='dialog'
				onClick={() => setDrawerOpen(true)}
			>
				{/* TODO: swtich to theme color when using eve theme */}
				<MenuIcon sx={{ color: 'white' }} />
			</IconButton>
			<Drawer
				onClose={() => setDrawerOpen(false)}
				open={drawerOpen}
				role='navigation'
				PaperProps={{ sx: { width: '66%' } }}
			>
				<List component='div'>
					{navigationItems.map(topNavItem => (
						<div key={topNavItem.key}>
							<ListItemButton
								{...(topNavItem.isLegacy && {
									component: Link,
									href: topNavItem.path,
									target: topNavItem.isExternal
										? '_blank'
										: '_self',
									rel: 'noreferrer',
								})}
								selected={
									(isActive && isActive.params.topNav) ===
									topNavItem.key
								}
							>
								<ListItemText primary={topNavItem.label} />
								{topNavItem.isDropdown &&
									(topNavItem.key === open ? (
										<ExpandMoreIcon
											onClick={() => setOpen(null)}
										/>
									) : (
										<KeyboardArrowRightIcon
											onClick={() =>
												setOpen(topNavItem.key)
											}
										/>
									))}
							</ListItemButton>
							{topNavItem.isDropdown && (
								<Collapse
									in={topNavItem.key === open}
									timeout='auto'
									unmountOnExit
								>
									<List component='div'>
										{topNavItem.menuItems.map(menuItem => (
											<ListItemButton
												key={menuItem.key}
												{...(menuItem.isLegacy
													? {
															component: Link,
															href: menuItem.path,
															target: '_self',
															rel: 'noreferrer',
													  }
													: {
															component:
																RouterLink,
															to: menuItem.path,
															onClick: () =>
																setDrawerOpen(
																	false
																),
													  })}
												sx={{
													color: palette.text.primary,
													pl: 4,
												}}
												selected={
													(isActive &&
														isActive.pathnameBase) ===
													menuItem.path
												}
											>
												<ListItemText
													primary={menuItem.label}
												/>
											</ListItemButton>
										))}
									</List>
								</Collapse>
							)}
						</div>
					))}
				</List>
				<Divider />
			</Drawer>
		</>
	);
};

MobileNavMenu.propTypes = {
	navigationItems: PropTypes.array.isRequired,
};

export default MobileNavMenu;
