import Typography from '@mui/material/Typography';
import ResourceTablePage from './shared/ResourceTablePage';
import FitnessFileType from '../../scripts/fitnessFileType';

const ProgramEducationLibrary = () => {
	const pageTitle = 'Program Education Library';
	const resourceColumnTitle = 'Program Education Library';
	const pageSummary = (
		<Typography fontWeight='fontWeightBold' mb={2}>
			The Program Education Library consists of a variety of
			administrative topics that will be valuable when servicing ASH
			fitness members.
		</Typography>
	);

	return (
		<ResourceTablePage
			pageTitle={pageTitle}
			resourceColumnTitle={resourceColumnTitle}
			pageSummary={pageSummary}
			fitnessFileType={FitnessFileType.ashlinkFitnessPELResource.value}
			disableReleaseDateColumn
			disableEffectiveDateColumn
		/>
	);
};

export default ProgramEducationLibrary;
