import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonIcon from '@mui/icons-material/Person';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import HomeLink from './HomeLink';
import useIsMobile from '../../../hooks/useIsMobile';
import NavigationBar from './NavigationBar';
import MobileNavMenu from './MobileNavMenu';
import MobileAccountMenu from './MobileAccountMenu';
import useSessionContext from '../../../data/queryHooks/useSessionContext';

import {
	getHeaderNavs,
	getProfileNavs,
	getCurrentPermission,
	getMessageCenterPath,
} from '../../../scripts/navigationHelpers';
import {
	pageKey,
	retrievePermissionWithKey,
} from '../../../scripts/pageConstants';
import { LOGOUT_URL } from '../../../scripts/navigationItems';
import UserInfoHeader from './UserInfoHeader';

const Header = () => {
	const isMobile = useIsMobile();
	const [anchorEl, setAnchorEl] = useState(null);
	const isProfileMenuOpen = Boolean(anchorEl);
	const profileBtnId = 'profile-btn';
	const profileMenuId = 'profile-menu';
	const { permissions, redirectionLinks } = useSessionContext();
	const onProfileBtnClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const { palette } = useTheme();

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		setAnchorEl(null);
		window.open(LOGOUT_URL, '_self', 'noreferrer');
	};

	const navigationItems = getHeaderNavs(permissions, redirectionLinks);
	const profileItems = getProfileNavs(permissions, redirectionLinks);
	const hasMessageCenterPermission = () => {
		if (permissions) {
			return !!getCurrentPermission(
				retrievePermissionWithKey[pageKey.messageCenter],
				permissions
			);
		}
		return false;
	};

	return (
		<AppBar
			position='static'
			sx={{
				backgroundColor: palette.common.white,
			}}
		>
			<Box
				sx={{
					width: '100%',
					backgroundColor: 'primary.dark',
				}}
			>
				<Container
					sx={{
						px: { xs: 1, sm: 1 },
						maxWidth: '100%',
					}}
				>
					<Toolbar
						sx={{
							justifyContent: 'space-between',
							py: 0,
							px: { xs: 0, sm: 0, md: 4 },
							minHeight: { xs: '64px', sm: '64px' },
							height: { xs: '64px', md: '80px' },
						}}
					>
						<Stack direction='row'>
							{isMobile && (
								<MobileNavMenu
									navigationItems={navigationItems}
								/>
							)}
							<HomeLink />
						</Stack>

						<Stack direction='row' spacing={1}>
							{hasMessageCenterPermission() ? (
								<IconButton
									component={Link}
									href={getMessageCenterPath(
										redirectionLinks
									)}
									target='_self'
									rel='noreferrer'
									aria-label='message center'
								>
									<MailOutlineIcon
										sx={{ color: palette.common.white }}
									/>
								</IconButton>
							) : null}

							{isMobile && (
								<MobileAccountMenu
									profileItems={profileItems}
									handleLogout={handleLogout}
								/>
							)}
							{!isMobile && (
								<>
									<IconButton
										id={profileBtnId}
										aria-controls={
											isProfileMenuOpen
												? profileMenuId
												: undefined
										}
										aria-haspopup='true'
										aria-expanded={
											isProfileMenuOpen
												? 'true'
												: undefined
										}
										aria-label='profile menu'
										data-testid='profile-menu'
										onClick={onProfileBtnClick}
									>
										<PersonIcon
											sx={{ color: palette.common.white }}
										/>
									</IconButton>
									<Menu
										id={profileMenuId}
										anchorEl={anchorEl}
										open={isProfileMenuOpen}
										onClose={handleClose}
										MenuListProps={{
											'aria-labelledby': profileBtnId,
										}}
									>
										{profileItems.map(menuItem => (
											<MenuItem
												key={menuItem.key}
												component={Link}
												href={menuItem.path}
												target='_self'
												rel='noreferrer'
											>
												{menuItem.label}
											</MenuItem>
										))}
										<MenuItem
											key='log-out'
											onClick={handleLogout}
										>
											Log Out
										</MenuItem>
									</Menu>
								</>
							)}
						</Stack>
					</Toolbar>
				</Container>
			</Box>
			{!isMobile && <NavigationBar items={navigationItems} />}
			{!isMobile && <UserInfoHeader />}
		</AppBar>
	);
};

export default Header;
