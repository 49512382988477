import FitnessPage from './shared/FitnessPage';

const Compliance = () => {
	const pageTitle = 'Compliance';
	const pageId = 'compliance'; // Content Owners need to create a fitness page with this ID

	return <FitnessPage pageTitle={pageTitle} pageId={pageId} />;
};

export default Compliance;
