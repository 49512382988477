import restDataService from './restDataService';

const ashlinkNextGatewayApiBasePath =
	'api/ashlinknextapigateway/Fitness/PostLogin';

export const GetFitnessResourcesFn = async (
	drFedId,
	fitnessFileType,
	pageNumber,
	pageSize
) =>
	restDataService
		.get(`${ashlinkNextGatewayApiBasePath}/FitnessResources`, {
			params: {
				DrFedID: drFedId,
				FileType: fitnessFileType,
				Page: pageNumber,
				PageSize: pageSize,
			},
		})
		.catch(error => error.response);

export const GetFAQBySpecialtyFn = async drFedId =>
	restDataService
		.get(`${ashlinkNextGatewayApiBasePath}/FAQBySpecialty`, {
			params: {
				DrFedID: drFedId,
			},
		})
		.catch(error => error.response);

export const GetFitnessPageFn = async (drFedId, pageId) =>
	restDataService
		.get(`${ashlinkNextGatewayApiBasePath}/FitnessPage`, {
			params: {
				DrFedID: drFedId,
				PageID: pageId,
			},
		})
		.catch(error => error.response);

export const GetSessionContext = async () => {
	try {
		return (await restDataService.get('auth/userparams')).data;
	} catch (error) {
		return error;
	}
};

export const GetStatus = async () => {
	try {
		return (await restDataService.get('auth/sessionstatus')).data;
	} catch (error) {
		return error;
	}
};
